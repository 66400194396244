.contentContainer {
  display: grid;
  overflow: hidden;
  grid-template-columns: 20vw 30vw;
  grid-template-rows: 50vh 5vh;
}
.contentContainerWithoutExamples {
  display: grid;
  overflow: hidden;
  grid-template-columns: 50vw;
  grid-template-rows: 50vh 5vh;
}
.imageContainer {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  justify-items: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.imageContainer img {
  width: auto;
  max-width: calc(20vw - 40px);
  max-height: calc(50vh - 40px);
  object-fit: cover;
  margin: 10px 0px 10px 0px;
  justify-items: center;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 10px;
}
.imageContainerWithoutExamples {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  justify-items: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.imageContainerWithoutExamples img {
  width: auto;
  max-width: calc(45vw - 40px);
  max-height: calc(50vh - 40px);
  object-fit: cover;
  margin: 10px 0px 10px 0px;
  justify-items: center;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 10px;
}
.exampleContainer {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  justify-items: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.exampleContainer img {
  width: auto;
  max-width: calc(20vw - 40px);
  max-height: 100%;
  object-fit: cover;
  margin: 10px 0px 10px 0px;
  justify-items: center;
  align-items: center;
  border: 4px solid #1a9c25;
  border-radius: 10px;
}
.navigationButtons {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.thumbnailContainer {
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  gap: 5px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.thumbnailContainerWithoutExamples {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  gap: 5px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.thumbnail {
  width: calc(5vh - 5px);
  height: calc(5vh - 5px);
  object-fit: cover;
  border-radius: 4px;
  border: 2px solid #ccc;
  cursor: pointer;
  transition: border-color 0.3s;
}
.thumbnailActive {
  border-color: #007bff;
}
.placeholderBox {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
}
.placeholderCross::before,
.placeholderCross::after {
  content: '';
  position: absolute;
  width: 80%;
  height: 2px;
  translate: -50%;
  background-color: #ccc;
}
.placeholderCross::before {
  transform: rotate(45deg);
}
.placeholderCross::after {
  transform: rotate(-45deg);
}
.fullscreenContainer {
  width: 100%;
  height: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}
.fullscreenContainer img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.dashboardHeaderContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
}
.dashboardHeaderContainer:first-child {
  margin-left: 1rem;
}
.inputsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.margin {
  margin-right: 16px;
}
.navButtonContainer {
  display: flex;
  flex-direction: row;
}

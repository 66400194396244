.green {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #06893c;
}
.blank {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: thin solid #767676;
}
.blue {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #002fd7;
}
.red {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #d70000;
}

.termsWrapper {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
  overflow: hidden;
  gap: 50px;
  padding: 20px;
}

.logo {
  width: 250px;
  height: auto;
  object-fit: contain;
}

.logoContainer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.termsContainer {
  max-width: 600px;
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  text-align: center;
}

.termsContainer a {
  color: #0073e6;
  text-decoration: none;
  font-weight: 600;
}

.termsContainer a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .termsContainer {
    max-width: 95%;
  }
}

.navButton {
  all: unset;
  width: 20px;
  height: 100%;
  overflow: hidden;
  color: #c2c2c2;
}
.navButton:hover {
  cursor: pointer;
  font-weight: 300;
  color: black;
}

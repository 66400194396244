.auditModal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 5vh 5vh 60vh 3vh 3vh;
  padding: 20px 40px 20px 40px;
  isolation: isolate;
  position: absolute;
  width: 80%;
  height: 80%;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.progressContainer {
  grid-column: 1 / 4;
  grid-row: 3 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageTitle {
  grid-column: 1 / 4;
  grid-row: 1 / 1;
  font-size: 28px;
  font-weight: 700;
}
.questionContainer {
  grid-column: 1 / 4;
  grid-row: 2 / 2;
  font-size: 14pt;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
}
.imagesContainer {
  grid-column: 1 / 3;
  grid-row: 3 / 3;
  height: 100%;
  width: 100%;
  justify-items: center;
  align-content: center;
  align-items: center;
}
.inputContainer {
  grid-column: 3 / 3;
  grid-row: 3 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 10px;
}
.buttonContainer {
  grid-column: 1 / 4;
  grid-row: 4 / 4;
  align-items: center;
  justify-items: center;
}
.footerContainer {
  display: flex;
  flex-direction: row;
  grid-column: 1 / 4;
  grid-row: 5 / 5;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
}
.footerButton {
  all: unset;
  font-weight: 400;
  font-style: italic;
  padding-right: 10px;
  cursor: pointer;
}
.footerButton:hover {
  color: #d70000;
}
.techCallContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  font-size: 14pt;
}

.passButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  background: white;
  border: 2px solid #1a9c25;
  border-radius: 100px;
  color: #1a9c25;
  min-width: 120px;
  max-height: 45px;
}

.passButton:hover {
  background: #1a9c25;
  color: white;
  cursor: pointer;
}

.failButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  background: white;
  /* background: #d70000; */
  border: 2px solid #d70000;
  /* border: none; */
  border-radius: 100px;
  /* color: white; */
  color: #d70000;
  min-width: 120px;
  max-height: 45px;
}

.failButton:hover {
  /* background: #a30000; */
  background: #d70000;
  color: white;
  cursor: pointer;
}

.buttonText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  justify-content: center;
}

.failButton:disabled {
  border: 1px solid #c2c2c2;
  background: #efefef;
  color: #767676;
  pointer-events: none;
}

.passButton:disabled {
  border: 1px solid #c2c2c2;
  background: #efefef;
  color: #767676;
  pointer-events: none;
}

.auditModal {
  display: flex;
  flex-direction: column;
  padding: 20px 40px 20px 40px;
  isolation: isolate;
  position: absolute;
  width: 60%;
  height: 60%;
  left: 50%;
  transform: translateX(-50%);
  top: 15%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.tableContaier {
  overflow-y: scroll;
}
.modalTitle {
  font-size: 28px;
}
